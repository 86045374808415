import { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { DawnArrowLeft16 } from '@xemplo/icons';

import {
  StyledIcon,
  StyledItem,
  StyledItems,
  StyledLabel,
  StyledSidebar,
  StyledToggle,
  StyledTooltip,
  StyledTooltipWrapper,
} from './sidebar.style';
import { SidebarItem, SidebarProps } from './sidebar.types';

export const SidebarTestId = {
  SidebarContainer: 'sidebar-container',
  SidebarToggle: 'sidebar-toggle',
  SidebarItem: (itemId: string) => `sidebar-item-${itemId}`,
  SidebarItemLabel: (itemId: string) => `sidebar-item-label${itemId}`,
  SidebarItemLink: (itemId: string) => `sidebar-item-link-${itemId}`,
  SidebarTooltip: 'sidebar-item-tooltip',
};

export function Sidebar({ items }: SidebarProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHandleHidden, setIsHandleHidden] = useState(true);
  const [tooltip, setTooltip] = useState('');
  const sidebarContainerRef = useRef<HTMLDivElement>(null);
  const tooltipElement = useRef<HTMLDivElement>(null);

  const handleTooltipVisible = useCallback(
    (event: SyntheticEvent, item: SidebarItem) => {
      if (isExpanded || item.isHeader || !tooltipElement.current) return;

      const { current } = tooltipElement;

      // Find the top offset of the item
      const { top } = (event.target as HTMLElement).getBoundingClientRect();

      // The offset will be different depending on the layout the sidebar
      // has been placed in. This is used to position the tooltip correctly.
      const sidebarOffset = sidebarContainerRef.current?.offsetTop || 0;

      // Account for the sidebar offset to position the tooltip correctly
      // +3 is manually adjust alignment centered to the icon
      const offset = top - sidebarOffset + 3;

      current.setAttribute('aria-hidden', 'false');
      current.style['top'] = `${offset}px`;
      setTooltip(item.label);
    },
    [isExpanded]
  );

  const handleTooltipHidden = useCallback(() => {
    if (isExpanded) return;
    if (tooltipElement.current) {
      tooltipElement.current.setAttribute('aria-hidden', 'true');
    }
  }, [isExpanded]);

  return (
    <StyledSidebar
      id="sidebar-nav"
      ref={sidebarContainerRef}
      data-testid={SidebarTestId.SidebarContainer}
      data-expanded={isExpanded}
      onMouseEnter={() => setIsHandleHidden(false)}
      onMouseLeave={() => setIsHandleHidden(true)}
    >
      <StyledItems role="menu" aria-busy="true">
        {items.map((item) => (
          <StyledItem
            key={item.id}
            role="presentation"
            data-heading={item.isHeader}
            data-testid={SidebarTestId.SidebarItem(item.id)}
            data-expanded={isExpanded}
            onMouseEnter={(e) => handleTooltipVisible(e, item)}
            onMouseLeave={() => handleTooltipHidden()}
          >
            {item.isHeader ? (
              <StyledLabel
                role="heading"
                aria-level={1}
                aria-label={item.label}
                data-testid={SidebarTestId.SidebarItemLabel(item.id)}
                data-expanded={isExpanded}
                data-header={item.isHeader}
                title={item.label}
              >
                {item.label}
              </StyledLabel>
            ) : (
              <NavLink
                to={item.route}
                role="menuitem"
                aria-describedby={`tooltip-${item.id}`}
                data-testid={SidebarTestId.SidebarItemLink(item.id)}
                onClick={() => item.callback && item.callback(item)}
              >
                {item.icon && (
                  <StyledIcon data-expanded={isExpanded}>{item.icon}</StyledIcon>
                )}
                <StyledLabel
                  data-testid={SidebarTestId.SidebarItemLabel(item.id)}
                  data-expanded={isExpanded}
                  data-header={item.isHeader}
                >
                  {item.label}
                </StyledLabel>
              </NavLink>
            )}
          </StyledItem>
        ))}
      </StyledItems>
      <StyledTooltipWrapper
        ref={tooltipElement}
        aria-hidden={true}
        data-testid={SidebarTestId.SidebarTooltip}
      >
        <StyledTooltip>{tooltip}</StyledTooltip>
      </StyledTooltipWrapper>
      <StyledToggle
        data-testid={SidebarTestId.SidebarToggle}
        data-expanded={isExpanded}
        aria-controls="sidebar-nav"
        aria-hidden={isHandleHidden}
        onClick={() => setIsExpanded(!isExpanded)}
        disabled={isHandleHidden}
      >
        <DawnArrowLeft16 />
      </StyledToggle>
      <StyledGradient />
    </StyledSidebar>
  );
}

const StyledGradient = styled(IconGradient)`
  position: absolute;
`;

/**
 * Need to figure it out whether we should make this a shareable thing. Unsure at this stage
 * This SVG is used to fill the icon path when an item is active.
 */
function IconGradient() {
  return (
    <svg
      height="0"
      width="0"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="primaryGradient"
          x1="0"
          y1="7.62939e-06"
          x2="19.4905"
          y2="19.8362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3991FF"></stop>
          <stop offset="0.501502" stopColor="#A48AFB"></stop>
          <stop offset="1" stopColor="#FA7066"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Sidebar;
