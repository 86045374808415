import { useGetPayrunAmendmentsQuery } from '@xemplo/payrun-query';
import { useServerSideState } from '@xemplo/table';

import { columns } from './amendment-modal.helper';
import * as S from './amendment-modal.styles';
export const AmendmentModalBody = ({ payrunId }: { payrunId: string }) => {
  const { pagination, onPaginationChange } = useServerSideState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { pageIndex, pageSize } = pagination;

  const { data, isLoading } = useGetPayrunAmendmentsQuery({
    id: payrunId,
    requestParams: {
      page: pageIndex + 1,
      per_page: pageSize,
    },
  });

  return (
    <S.ModalBodyContainer>
      <S.StyledTable
        minWidth={0}
        minHeight={184}
        maxHeight={664}
        enablePagination
        manualPagination
        isLoading={isLoading}
        data={data?.result?.rows ?? []}
        columns={columns}
        state={{ pagination }}
        enableRowSelection={false}
        pageCount={data?.result?.total_Pages ?? 0}
        totalRowCount={data?.result?.total ?? 0}
        enableSorting={false}
        onPaginationChange={onPaginationChange}
      />
    </S.ModalBodyContainer>
  );
};
