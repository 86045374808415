import styled, { css } from 'styled-components';

import { Colour, Shadow } from '@xemplo/style-constants';

import { CheckboxSize } from '../v1/checkbox.types';

/** Minus symbol for indeterminate state */
export const Rectangle = styled.div`
  width: 16px;
  height: 2px;
  background: #ffffff;

  &[data-size='small'] {
    width: 10px;
  }
`;

export const Label = styled.label`
  display: flex;
  box-sizing: border-box;
  position: relative;
  border-radius: 50px;

  width: 24px;
  height: 24px;

  &[data-size='small'] {
    width: 16px;
    height: 16px;
  }
`;

/** Styles for checked state (and indeterminate) */
const checkedStyles = css`
  background: ${Colour.Blue[500]};
  color: White;
  text-align: center;
  border: 2px solid ${Colour.Blue[500]};
  box-shadow: none;
`;

/** Underlying checkbox element.  */
export const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
  /** If disabled, remove cursor effect and change opacity */
  &:disabled + span {
    pointer-events: none;
    opacity: 0.2;
  }
`;

/** Styled span is the visual component of the checkbox */
export const Checkbox = styled.span<{ $size: CheckboxSize }>`
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: 0.1s all ease-out;
  border-radius: 4px;
  border: 2px solid ${Colour.Gray[300]};
  display: flex;
  align-items: center;
  justify-content: center;

  /** If error state apply red border and shadow */
  &.has-error {
    border: 2px solid ${Colour.Red[500]};
    box-shadow: ${Shadow.Error};
  }

  /** needed for the circular box shadow effect on hover */
  &::before {
    content: '';
    position: absolute;
    opacity: 0;
    width: ${({ $size }) => ($size === CheckboxSize.Standard ? '24px' : '16px')};
    height: ${({ $size }) => ($size === CheckboxSize.Standard ? '24px' : '16px')};
    border-radius: 50%;
    background: ${Colour.Black[5]};
    box-shadow: ${({ $size }) =>
      $size === CheckboxSize.Standard
        ? `0 0 0 12px ${Colour.Black[5]}`
        : `0 0 0 8px ${Colour.Black[5]}`};
    transition: 0.1s all ease-out;
  }
  /** Hover effects */
  &&:hover {
    &::before {
      opacity: 1;
    }

    &[data-checked='true']::before {
      box-shadow: ${({ $size }) =>
        $size === CheckboxSize.Standard
          ? `0 0 0 12px rgb(0, 96, 215, 0.1)`
          : `0 0 0 8px rgb(0, 96, 215, 0.1)`};
    }
  }

  /** If checked, change background color of the span to blue */
  /** Indeterminate state uses same styled as checked - regardless of checked state */
  &[data-checked='true'] {
    ${checkedStyles};
  }
`;
