import { AxiosInstance } from 'axios';
import { jsonrepair } from 'jsonrepair';

import { DownloadableFile } from '@xemplo/gp-types';

export const handleDownloadFile = async (
  file: DownloadableFile,
  client: AxiosInstance
) => {
  const response = await client.get(file.url, {
    responseType: 'blob',
  });
  const blob = new Blob([response.data]);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${file.type}.${file.extension}`;
  link.click();
};

export function tryJsonParse<T>(jsonString: string) {
  try {
    const trimmedJsonString = jsonString.trim();
    if (trimmedJsonString === '') return null;

    const sanitizedString = jsonrepair(trimmedJsonString);
    return JSON.parse(sanitizedString) as T;
  } catch (error) {
    console.log('Failed to parse string json: ', { value: jsonString, error });
    return null;
  }
}

export function formatToCurrency({
  value = 0,
  currency = 'AUD',
  locale = 'en-AU',
}: {
  value?: number | null;
  currency?: string;
  locale?: string;
}): string {
  const defaultValue = value ?? 0;
  const formattedNumber = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
    signDisplay: 'never',
  }).format(defaultValue);

  return defaultValue < 0 ? `(${formattedNumber})` : formattedNumber;
}

export function validateWhitespacesAsEmpty(value?: string | null) {
  if (!value) return 'This field is required';
  const valid = value.trim().length > 0;
  return valid || 'This field is required';
}
