import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AMENDMENT_QUERY_KEYS, PAYRUN_QUERY_KEYS } from '@xemplo/gp-constants';
import {
  Amendment,
  AmendmentChangeLog,
  AmendmentCreate,
  AmendmentFile,
  BusinessUnit,
  ChangeSubTask,
  ChangeTask,
  LegacyListPagedResult,
  ListPagedResult,
  PayInstructionPayload,
  QueryProps,
  QueryPropsWithId,
  Result,
} from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { DEFAULT_QUERY_PROPS } from '@xemplo/query-helpers';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { AmendmentAPI } from './amendment-query.constants';
import {
  GetAmendmentsQueryString,
  ResubmitPayrunAmendmentProps,
  useCreateAmendmentProps,
  useCreatePayInstructionProps,
  useDeleteAmendmentProps,
  useDeletePayrunAmendmentProps,
} from './amendment-query.types';

export const useGetAmendmentsQuery = (
  props: QueryProps<ListPagedResult<Amendment>, GetAmendmentsQueryString>
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(AmendmentAPI.amendments, applicationApiUrl, requestParams);

  return useQuery<ListPagedResult<Amendment>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS, url],
    queryFn: ({ signal }) =>
      client.get<ListPagedResult<Amendment>>(url, { signal }).then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
};

export const useGetAmendmentByIdQuery = (props: QueryPropsWithId<Result<Amendment>>) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { queryProps, id } = props;
  const url = apiUrlBuilder(AmendmentAPI.amendmentById(id), applicationApiUrl);

  return useQuery<Result<Amendment>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID, url],
    queryFn: ({ signal }) =>
      client.get<Result<Amendment>>(url, { signal }).then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!id,
  });
};

export const useGetAmendmentChangeLogQuery = (
  props: QueryPropsWithId<ListPagedResult<AmendmentChangeLog>>
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { queryProps, id } = props;
  const url = apiUrlBuilder(AmendmentAPI.changeLogById(id), applicationApiUrl);

  return useQuery<ListPagedResult<AmendmentChangeLog>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url],
    queryFn: ({ signal }) =>
      client
        .get<ListPagedResult<AmendmentChangeLog>>(url, { signal })
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!id,
  });
};

export const useGetChangeTasksQuery = (
  props: QueryProps<ListPagedResult<ChangeTask>>
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(AmendmentAPI.changeTasks, applicationApiUrl, requestParams);

  return useQuery<ListPagedResult<ChangeTask>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: () => client.get<ListPagedResult<ChangeTask>>(url).then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
};

export const useGetChangeTaskSubTypeQuery = (
  props: QueryProps<ListPagedResult<ChangeSubTask>>
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(
    AmendmentAPI.changeTaskSubTypes,
    applicationApiUrl,
    requestParams
  );

  return useQuery<ListPagedResult<ChangeSubTask>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: () =>
      client.get<ListPagedResult<ChangeSubTask>>(url).then((resp) => resp.data),
    enabled:
      !!applicationApiUrl &&
      !!requestParams?.q?.changeTaskValueId &&
      !!requestParams?.q?.businessUnitId,
  });
};

export const useGetBusinessUnitsQuery = (
  props: QueryProps<ListPagedResult<BusinessUnit>>
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { requestParams, queryProps } = props;
  const url = apiUrlBuilder(AmendmentAPI.businessUnits, applicationApiUrl, requestParams);

  return useQuery<ListPagedResult<BusinessUnit>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url, applicationApiUrl, requestParams],
    queryFn: () =>
      client.get<ListPagedResult<BusinessUnit>>(url).then((resp) => resp.data),
    enabled: !!applicationApiUrl,
  });
};

export const useGetAmendmentFilesQuery = (
  props: QueryPropsWithId<LegacyListPagedResult<AmendmentFile>>
) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { queryProps, id } = props;
  const url = apiUrlBuilder(AmendmentAPI.filesByAmendmnetId(id), applicationApiUrl);

  return useQuery<LegacyListPagedResult<AmendmentFile>>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url],
    queryFn: ({ signal }) =>
      client
        .get<LegacyListPagedResult<AmendmentFile>>(url, { signal })
        .then((resp) => resp.data),
    enabled: !!applicationApiUrl && !!id,
  });
};

export const useGetAmendmentFileDownloadQuery = (props: QueryPropsWithId<Blob>) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const { queryProps, id } = props;
  const url = apiUrlBuilder(AmendmentAPI.downloadFileByFileId(id), applicationApiUrl);

  return useQuery<Blob>({
    ...DEFAULT_QUERY_PROPS,
    ...queryProps,
    queryKey: [url],
    queryFn: ({ signal }) =>
      client.get<Blob>(url, { signal, responseType: 'blob' }).then((resp) => {
        const blob = new Blob([resp.data], { type: resp.headers['content-type'] });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.setAttribute('href', url);
        // Extract filename from content-disposition header
        const contentDisposition = resp.headers['content-disposition'];
        const filename = contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '');
        link.setAttribute('download', filename);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
        return blob;
      }),
    enabled: !!applicationApiUrl && !!id,
  });
};

/**
 * Hook for creating amendments
 * @returns useMutation hook (Tanstack query) for submitting the create amendment post request
 */
export const useCreateAmendment = ({ onSuccess, onError }: useCreateAmendmentProps) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(AmendmentAPI.bulkCreateAmendment, applicationApiUrl);
  return useMutation({
    mutationFn: (payload: AmendmentCreate) =>
      client.post<LegacyListPagedResult<string>>(url, payload).then((resp) => resp.data),
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS],
      });
      onSuccess?.(data, variants, context);
    },
    onError,
  });
};

/**
 * Hook for deleting individial payrun amendments by Id
 * @param props useDeletePayrunAmendmentProps
 * @returns useMutation hook (Tanstack query) for submitting the delete request to the payrun amendment action endpoint
 */
export const useDeletePayrunAmendment = (props: useDeletePayrunAmendmentProps) => {
  const { payrunId, amendmentId, onError, onSuccess } = props;
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();

  const url = apiUrlBuilder(
    AmendmentAPI.deletePayrunAmendmentById(payrunId, amendmentId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: () => client.delete<Amendment>(url).then((resp) => resp.data),
    onSuccess: (data: Amendment, variables: void, context: unknown) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS] });
      onSuccess?.(data, variables, context);
    },
    onError,
  });
};

/**
 * Hook for deleting individial amendments by Id
 * @returns useMutation hook (Tanstack query) for submitting the delete request to the amendment action endpoint
 */
export const useDeleteAmendment = (props: useDeleteAmendmentProps) => {
  const { amendmentId, onError, onSuccess } = props;
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();

  const url = apiUrlBuilder(AmendmentAPI.amendmentById(amendmentId), applicationApiUrl);

  return useMutation({
    mutationFn: () => client.delete<Amendment>(url).then((resp) => resp.data),
    onSuccess: (data: Amendment, variables: void, context: unknown) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS] });
      queryClient.invalidateQueries({ queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS] });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID],
      });
      onSuccess?.(data, variables, context);
    },
    onError,
  });
};

export const useResubmitPayrunAmendment = (props: ResubmitPayrunAmendmentProps) => {
  const { payrunId, amendmentId, onError, onSuccess } = props;
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();

  const url = apiUrlBuilder(
    AmendmentAPI.resubmitPayrunAmendmentById(payrunId, amendmentId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: (payload: { description: string }) =>
      client.post<LegacyListPagedResult<string>>(url, payload).then((resp) => resp.data),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({ queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS] });
      queryClient.invalidateQueries({ queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS] });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID],
      });
      onSuccess?.(data, variables, context);
    },
    onError,
  });
};

/**
 * Seperate Hook for creating pay instruction amendments
 * @returns useMutation hook (Tanstack query) for submitting the create amendment post request
 */
export function useCreatePayInstruction(props: useCreatePayInstructionProps) {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(AmendmentAPI.createPayInstruction, applicationApiUrl);

  return useMutation({
    //payload should confirm to the PayInstructionPayload interface defined in gp-types
    mutationFn: (payload: PayInstructionPayload) => {
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      return client.post(url, formData).then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
}
