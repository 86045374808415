import { ErrorBoundary } from "@xemplo/error-boundary";
import { Route, Routes } from "react-router-dom";
import {
  Features,
  PayrunV2Routes,
  PublicRoutes,
  RoutesWithSidebar,
  RoutesWithoutSidebar,
  RoutesWithoutSidebarAndNavbar,
  V2AmendmentsScreenRoute,
  V2DashboardRoute,
  V2RoutesWithTemplate,
  V2UsersScreenRoute,
} from "../constants";
import { useAppInit } from "../hooks/useAppInit";
import TemplateNoSidebarAndNavbar from "../Layout/TemplateNoSidebarAndNavbar";
import TemplateWithSidebar from "../Layout/TemplateSidebar";
import { V2FeatureRoute } from "./V2FeatureRoute";
import { V2FeatureTemplate } from "../Layout/V2FeatureTemplate";
import { AuthorisationRoutes } from "@xemplo/authorisation-router";
import { PersonaRole } from "@xemplo/gp-types";

export const RouteInit = () => {
  useAppInit();

  // Should probably add all routes that requires authentication, and split the routes that requires claims
  // then we can iterate through them and wrap the element with the RequireAuth and RequireSecureComponent
  return (
    <Routes>
      {/* Fullscreen routes (no layout template)*/}
      {[...PublicRoutes, ...RoutesWithoutSidebar].map((data) => {
        return (
          <Route
            key={data.path}
            path={data.path}
            element={data.element}
            errorElement={<ErrorBoundary />}
          />
        );
      })}
      {AuthorisationRoutes.map((data) => {
        return (
          <Route
            key={data.path}
            path={data.path}
            Component={data.Component}
            errorElement={<ErrorBoundary />}
          />
        );
      })}
      {/* /payrun/:id */}
      {V2FeatureRoute(PayrunV2Routes, Features.PayrunV2, [
        PersonaRole.CompanyAdmin,
        PersonaRole.CompanyAccountant,
      ])}
      {/** V2 Page routes(template includes sidebar and global nav) */}
      <Route element={<V2FeatureTemplate />}>
        {/* /payrun  */}
        {V2FeatureRoute(V2RoutesWithTemplate, Features.PayrunListV2, [
          PersonaRole.CompanyAdmin,
          PersonaRole.CompanyAccountant,
          PersonaRole.PSPAdmin,
          PersonaRole.PayrollManager,
          PersonaRole.SystemAdmin,
        ])}
        {/* /home (dashboard) */}
        {V2FeatureRoute(V2DashboardRoute, Features.DashboardV2)}
        {/* /amendments */}
        {V2FeatureRoute(V2AmendmentsScreenRoute, Features.AmendmentsV2, [
          PersonaRole.CompanyAdmin,
        ])}{" "}
        {/* /users */}
        {V2FeatureRoute(V2UsersScreenRoute, Features.UsersV2, [
          PersonaRole.CompanyAdmin,
          PersonaRole.CompanyAccountant,
          PersonaRole.PSPAdmin,
          PersonaRole.PayrollManager,
          PersonaRole.SystemAdmin,
        ])}
      </Route>
      {/** Routes with Sidebar, global nav, and header layout */}
      <Route element={<TemplateWithSidebar />}>
        {RoutesWithSidebar.map((data) => {
          return (
            <Route
              key={data.path}
              path={data.path}
              element={data.element}
              errorElement={<ErrorBoundary />}
            />
          );
        })}
      </Route>
      {/** Verify Details Route */}
      <Route element={<TemplateNoSidebarAndNavbar />}>
        {RoutesWithoutSidebarAndNavbar.map((data) => {
          return (
            <Route
              key={data.path}
              path={data.path}
              element={data.element}
              errorElement={<ErrorBoundary />}
            />
          );
        })}
      </Route>
    </Routes>
  );
};
