import styled from 'styled-components';

import { BodySmallMedium, BodySmallSemiBold, Colour } from '@xemplo/style-constants';

import { DefaultItemCounter } from '../item-counter';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 20px 24px;
  margin-top: auto;
`;

/**
 * The DefaultItemCounter has generic props. Due to the way styled-components works,
 * we need to redeclare it with the correct props.
 *
 * See https://github.com/styled-components/styled-components/issues/1803
 */
export const ItemCounter = styled(DefaultItemCounter)`
  ${BodySmallMedium};
  color: ${Colour.Gray[400]};
  position: absolute;
  left: 24px;
` as typeof DefaultItemCounter;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > span {
    ${BodySmallSemiBold};
    color: ${Colour.Gray[700]};
    min-width: 96px;
    text-align: center;
    flex-shrink: 0;
  }
`;

export const PageSizeSelector = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  right: 24px;
`;
