import {
  ChangeEvent,
  forwardRef,
  Ref,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import Checkmark from '../checkmark';

import * as S from './checkbox.styles';
import { CheckboxProps, CheckboxSize } from './checkbox.types';

export const CheckboxTestId = {
  input: (id?: string | number) => `checkbox-input-${id}`,
  label: (id?: string | number) => `checkbox-label-${id}`,
  span: 'checkbox-span',
};

function CheckboxInternal(props: CheckboxProps, ref?: Ref<HTMLInputElement>) {
  const {
    ariaLabel,
    id,
    name,
    value,
    checked = false,
    disabled = false,
    error = false,
    indeterminate = false,
    onChange,
    size = CheckboxSize.Standard,
  } = props;

  const localInputRef = useRef<HTMLInputElement>(null);
  const inputRef = (ref as RefObject<HTMLInputElement>) ?? localInputRef;

  const [checkedState, setCheckedState] = useState(checked);
  const [indeterminateState, setIndeterminateState] = useState(indeterminate);
  const [errorState, setErrorState] = useState(error);

  useEffect(() => {
    if (inputRef.current) {
      setIndeterminateState(indeterminate);
      inputRef.current.indeterminate = indeterminate;
    }
    if (checked != null && inputRef.current) {
      inputRef.current.checked = checked;
      setErrorState(error);
      setCheckedState(checked);
    }
  }, [checked, error, indeterminate, inputRef]);

  //On change, update the checked state and call the onChange callback
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    setCheckedState(event.target.checked);
    //Reset indeterminate state to false
    setIndeterminateState(false);
    inputRef.current && (inputRef.current.indeterminate = false);
    onChange?.(event);
  };

  return (
    <S.Label
      data-size={size}
      data-testid={CheckboxTestId.label(id || value)}
      data-role="checkbox"
    >
      <S.HiddenInput
        id={id}
        name={name}
        ref={inputRef}
        value={value}
        onChange={handleChange}
        aria-label={ariaLabel}
        data-testid={CheckboxTestId.input(id || value)}
        disabled={disabled}
        data-role="checkbox"
        role="checkbox"
      />
      <S.Checkbox
        data-error={errorState}
        data-testid={CheckboxTestId.span}
        $size={size}
        data-checked={checkedState || indeterminateState}
        data-role="checkbox"
      >
        <Checkmark
          size={size}
          checked={checkedState}
          indeterminate={indeterminateState}
          data-role="checkbox"
        />
      </S.Checkbox>
    </S.Label>
  );
}

/**
 * @deprecated Use `CheckboxV2` from `@xemplo/checkbox` instead. Deprecated since FD-1231
 * More info here https://expedo.atlassian.net/wiki/spaces/CL/pages/2008186924/Forms+and+Inputs
 */
export const Checkbox = forwardRef(CheckboxInternal);
export default Checkbox;
