import { ChangeEvent, useCallback, useEffect, useRef } from 'react';

import { StyledHiddenInput, StyledLabel, StyledToggle } from './toggle.style';
import { ToggleProps, ToggleSize } from './toggle.types';

export const ToggleTestId = {
  ToggleContainer: 'toggle-container',
  ToggleLabel: 'toggle-label',
};

export const Toggle = (props: ToggleProps) => {
  const {
    value,
    checked,
    onChange,
    name,
    disabled,
    size = ToggleSize.Standard,
    id,
    ariaLabel,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (checked != null && inputRef.current) {
      inputRef.current.checked = checked;
    }
  }, [checked]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      !disabled && onChange && onChange(event);
    },
    [onChange, disabled]
  );

  return (
    <StyledLabel size={size} data-testid={ToggleTestId.ToggleLabel}>
      <StyledHiddenInput
        ref={inputRef}
        id={id}
        aria-label={ariaLabel}
        type="checkbox"
        name={name}
        value={value}
        disabled={!!disabled}
        onChange={handleChange}
        data-role="checkbox"
      />
      <StyledToggle size={size} data-role="checkbox" />
    </StyledLabel>
  );
};

export default Toggle;
