import { useEffect, useMemo, useState } from 'react';

import { useDeleteAmendment } from '@xemplo/amendment-query';
import { ButtonType } from '@xemplo/button';
import { DawnTriangleAlert } from '@xemplo/icons';
import { HeaderIconMode, ModalBodyOverflow, ModalSize, ModalTheme } from '@xemplo/modal';
import { PromptProps, PromptType } from '@xemplo/prompts';
import { ToastType, useToast } from '@xemplo/toast';

import * as S from './remove-amendment-button.styles';
export interface UseRemoveAmendmentPromptProps {
  amendmentId: string;
  onSuccess?: () => void;
}

export const useRemoveAmendmentPrompt = (props: UseRemoveAmendmentPromptProps) => {
  const { amendmentId, onSuccess } = props;
  const [promptVisible, setPromptVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addToast } = useToast();

  const { mutateAsync: deleteAmendment } = useDeleteAmendment({
    onSuccess: () => {
      addToast({
        text: 'Amendment deleted',
        type: ToastType.Confirmation,
      });
      setPromptVisible(false);
      onSuccess?.();
    },
    onError: (error) => {
      const errorMessage = error.message ?? 'Something went wrong, please try again.';
      setErrorMessage(errorMessage);
    },
    amendmentId,
  });

  //config for prompt modal
  const initalPromptProps = useMemo<PromptProps>(() => {
    return {
      type: PromptType.Alert,
      theme: ModalTheme.Light,
      size: ModalSize.Small,
      closeButton: { hide: true },
      header: {
        title: 'Remove Amendment',
        headerIcon: {
          icon: <DawnTriangleAlert />,
          mode: HeaderIconMode.Worker,
        },
      },
      body: {
        content: (
          <S.PromptBody>
            Are you sure you want to remove amendment? You can't undo changes.
          </S.PromptBody>
        ),
        overflow: ModalBodyOverflow.Extended,
      },
      footer: {
        btnPrimary: {
          label: 'Remove',
          ariaLabel: 'confirm remove button',
          onClickAsync: () => deleteAmendment(),
          type: ButtonType.Primary,
        },
        btnSecondary: {
          label: 'Cancel',
          ariaLabel: 'Cancel remove button',
          type: ButtonType.Secondary,
          onClick: () => {
            setErrorMessage(null);
            setPromptVisible(false);
          },
        },
      },
    };
  }, [deleteAmendment]);

  useEffect(() => {
    if (errorMessage) {
      setPromptProps({
        ...initalPromptProps,
        body: {
          content: (
            <>
              <S.PromptBody>
                Are you sure you want to remove amendment? You can't undo changes.
              </S.PromptBody>
              <S.ErrorMessage>
                <DawnTriangleAlert />
                {errorMessage}
              </S.ErrorMessage>
            </>
          ),
          overflow: ModalBodyOverflow.Extended,
        },
      });
    } else {
      setPromptProps(initalPromptProps);
    }
  }, [errorMessage, initalPromptProps]);

  const [promptProps, setPromptProps] = useState<PromptProps>(initalPromptProps);

  return { promptProps, promptVisible, setPromptVisible };
};
