export const BreakpointSize = {
  small: 320,
  medium: 720,
  large: 922,
  xlarge: 1168,
  xxlarge: 1376,
  xxxlarge: 1648,
};

export enum Breakpoint {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
  XXLarge = 'xxlarge',
  XXXLarge = 'xxxlarge',
}

export const MinBreakpoint = {
  small: `(min-width: ${BreakpointSize.small}px)`,
  medium: `(min-width: ${BreakpointSize.medium}px)`,
  large: `(min-width: ${BreakpointSize.large}px)`,
  xlarge: `(min-width: ${BreakpointSize.xlarge}px)`,
  xxlarge: `(min-width: ${BreakpointSize.xxlarge}px)`,
  xxxlarge: `(min-width: ${BreakpointSize.xxxlarge}px)`,
};

export const MaxBreakpoint = {
  small: `(max-width: ${BreakpointSize.small}px)`,
  medium: `(max-width: ${BreakpointSize.medium}px)`,
  large: `(max-width: ${BreakpointSize.large}px)`,
  xlarge: `(max-width: ${BreakpointSize.xlarge}px)`,
  xxlarge: `(max-width: ${BreakpointSize.xxlarge}px)`,
  xxxlarge: `(max-width: ${BreakpointSize.xxxlarge}px)`,
};
