import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { Badge, BadgeColour } from '@xemplo/badge';
import { formatDate } from '@xemplo/date-time';
import {
  PayrunDetail,
  PayrunSteps,
  PersonaRole,
  StatusToStepMap,
} from '@xemplo/gp-types';
import { transformStatus } from '@xemplo/gp-utils';
import { DawnCashStack } from '@xemplo/icons';
import { StartDateCell } from '@xemplo/table';

import * as S from './payrun-base-table.styles';
import { ColumnIds, ColumnVisibility } from './payrun-base-table.types';
import { MilestoneCell, PayDateCell } from './table-cells';

export const columns: ColumnDef<PayrunDetail>[] = [
  {
    id: ColumnIds.Name,
    accessorKey: 'name',
    header: 'Name',
    size: 270,
    cell: ({ row }) => {
      return (
        <S.PayrunNameCell>
          <S.PayrunIcon>
            <DawnCashStack />
          </S.PayrunIcon>
          <div>{row.original.name}</div>
        </S.PayrunNameCell>
      );
    },
  },
  {
    id: ColumnIds.Company,
    accessorKey: 'companyName',
    header: 'Company',
    cell: ({ row }) => <S.TrunctatedText>{row.original.companyName}</S.TrunctatedText>,
  },
  {
    id: ColumnIds.BusinessUnitName,
    accessorKey: 'businessUnitName',
    header: 'Business Unit',
    cell: ({ row }) => (
      <S.TrunctatedText>{row.original.businessUnitName}</S.TrunctatedText>
    ),
  },
  {
    id: ColumnIds.Frequency,
    accessorKey: 'payOccurrenceName',
    header: 'Frequency',
    cell: ({ row }) =>
      row.original.payOccurrenceName ? row.original.payOccurrenceName : '-',
  },
  {
    id: ColumnIds.StartDate,
    accessorKey: 'payCycleStartDate',
    header: 'Start Date',
    cell: ({ row }) =>
      row.original.payCycleStartDate ? (
        <StartDateCell date={row.original.payCycleStartDate} />
      ) : (
        '-'
      ),
  },
  {
    id: ColumnIds.PayrunTypeName,
    accessorKey: 'payrunTypeName',
    header: 'Type',
  },
  {
    id: ColumnIds.AmendmentDueDate,
    accessorKey: 'ammendmentDueDateTime',
    header: 'Amendment Due Date',
    cell: ({ row }) => <StartDateCell date={row.original.ammendmentDueDateTime} />,
  },
  {
    id: ColumnIds.AmmendmentCount,
    accessorKey: 'ammendmentCount',
    header: 'Ammendments',
    cell: ({ row }) => (
      <Badge
        color={BadgeColour.LightWhite}
        value={row.original.ammendmentCount.toString()}
      />
    ),
  },

  {
    id: ColumnIds.PayDate,
    accessorKey: 'payDate',
    header: 'Pay date',
    size: 240,
    cell: ({ row }) => <PayDateCell {...row} />,
  },
  {
    id: ColumnIds.PayrunStatusID,
    accessorKey: 'payrunStatusID',
    header: 'Milestone',
    size: 150,
    cell: ({ row }) => <MilestoneCell {...row} />,
  },
];

//The active table should only show payruns that are not completed or draft
export const allActiveStepIds = [
  StatusToStepMap(true)[PayrunSteps.Submit],
  StatusToStepMap(true)[PayrunSteps.Prepare],
  StatusToStepMap(true)[PayrunSteps.Review],
  StatusToStepMap(true)[PayrunSteps.Pending],
  StatusToStepMap(true)[PayrunSteps.Payment],
  StatusToStepMap(true)[PayrunSteps.Process],
].flat();

export const activeStatusFilter = transformStatus(allActiveStepIds);

/**
 * Calculates the milestone due date, the difference between the current date and the milestone due date, and the current milestone.
 * @param payrun - The payrun object.
 * @returns - An object containing the following properties:
 *   - milestoneDueDate: The due date of the current milestone.
 *   - dateDiff: The difference between the current date and the milestone due date.
 *   - activeStep: The current milestone.
 **/
export function getMilestoneDateObj(payrun: PayrunDetail) {
  const stepMap = {
    ...StatusToStepMap(payrun.isInvoiceNeeded),
    Complete: [17, 18],
    Draft: [23, 24],
  };
  const activeStep = Object.keys(stepMap).find((step) =>
    stepMap[step as keyof typeof stepMap].includes(payrun.payrunStatusID)
  );
  const milestoneDueDate = dayjs(getStepDueDate(activeStep as PayrunSteps, payrun));
  const dateDiff = milestoneDueDate.diff(dayjs(), 'day');
  const dueTime = formatDate({ value: milestoneDueDate.toDate(), format: 'h:mmA' });

  return { milestoneDueDate, dateDiff, activeStep, dueTime };
}

const getStepDueDate = (step: PayrunSteps, payrun: PayrunDetail) => {
  const dueDateMap = {
    [PayrunSteps.Submit]: payrun.ammendmentDueDateTime,
    [PayrunSteps.Prepare]: payrun.preparationDueDateTime,
    [PayrunSteps.Review]: payrun.approvalDueDateTime,
    [PayrunSteps.Pending]: payrun.paymentDueDateTime,
    [PayrunSteps.Payment]: payrun.paymentDueDateTime,
    [PayrunSteps.Process]: payrun.payDate,
    [PayrunSteps.Complete]: payrun.payDate,
  };
  return dueDateMap[step];
};

export function SetColumnVisiblityByRoleCode(
  roleCode: string
): Partial<ColumnVisibility> {
  switch (roleCode) {
    case PersonaRole.SystemAdmin:
    case PersonaRole.PSPAdmin:
    case PersonaRole.PayrollManager:
      return {};
    default:
      return {
        [ColumnIds.Company]: false,
        [ColumnIds.BusinessUnitName]: false,
        [ColumnIds.Frequency]: false,
        [ColumnIds.StartDate]: false,
        [ColumnIds.AmendmentDueDate]: false,
      };
  }
}
