import { PAYRUN_COMPONENTS, ROLE_AUTHORISATION_MODULE_IDS } from '@xemplo/gp-api';
import { HeaderIconMode, useModal } from '@xemplo/modal';
import { useRoleAuthorisation } from '@xemplo/roles-query';

import { ModalBody } from './modal/modal-body';
import { ModalHeaderIcon } from './modal/modal-header-icon';
import { useClosePrompt } from './modal/use-close-prompt';
import { CreateAmendmentProps } from './create-amendment.types';

export const useCreateAmendment = (props: Readonly<CreateAmendmentProps>) => {
  const { onSuccess, payrunModeValues } = props;
  const { configureModal } = useModal();
  const { promptProps, setPromptVisible } = useClosePrompt();

  function handleClick(selectedWorkers?: string[]) {
    configureModal({
      open: true,
      disableOutsideClick: true,
      header: {
        title: 'New Amendment',
        headerIcon: {
          icon: <ModalHeaderIcon />,
          mode: HeaderIconMode.Simple,
        },
      },
      body: {
        content: (
          <ModalBody
            setPromptVisible={setPromptVisible}
            onSuccess={onSuccess}
            payrunModeValues={payrunModeValues}
            selectedWorkers={selectedWorkers}
          />
        ),
      },
      onCloseCallback: () => setPromptVisible(true),
    });
  }
  //NOTE: We don't currenlty have a role authorisation object setup for Amendments. The payrun create amendmnet has the same permissions so we are using that for now.
  // Once BE has updated the amendments object we can update this to use amendments module id. https://expedo.atlassian.net/browse/FD-1365
  const { componentVisible } = useRoleAuthorisation(ROLE_AUTHORISATION_MODULE_IDS.payrun);
  const showButton = componentVisible(PAYRUN_COMPONENTS.AMENDMENT_CREATE);

  return {
    handleClick,
    promptProps,
    showButton,
  };
};
