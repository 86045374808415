import { Amendment } from '@xemplo/gp-types';
import { useModal } from '@xemplo/modal';
import { PayrunResubmitAmendment } from '@xemplo/payrun-resubmit-amendment';

import { amendmentDetailsModalTestIds } from '../../../use-amendment-deatils-modal';
import * as S from '../../amendment-details-modal-styles';

import { RemoveAmendmentButton } from './remove-amendment-button/remove-amendment-button';
export interface AmendmentDetailModalFooterProps {
  amendment: Amendment;
}
export const AmendmentDetailModalFooter = (props: AmendmentDetailModalFooterProps) => {
  const { amendment } = props;

  const { toggleModal } = useModal();

  return (
    <S.FooterWrapper data-testid={amendmentDetailsModalTestIds.footer}>
      <RemoveAmendmentButton amendment={amendment} onSuccess={() => toggleModal(false)} />
      <PayrunResubmitAmendment
        onSuccess={() => toggleModal(false)}
        amendment={amendment}
      />
    </S.FooterWrapper>
  );
};
