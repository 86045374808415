import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from './date-time.const';
import { FormatDateProps } from './date-time.types';

export const formatDate = ({ value, format = DEFAULT_DATE_FORMAT }: FormatDateProps) => {
  if (!value) return 'N/A';
  return dayjs(value).format(format);
};

export const isPastDate = (date?: string | Date) => {
  if (!date) return false;
  return dayjs().isAfter(dayjs(date));
};
