import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDatePicker } from '@react-aria/datepicker';
import { useDatePickerState } from '@react-stately/datepicker';
import classNames from 'classnames';

import { Calendar } from '@xemplo/calendar';
import { FormDialogPopover } from '@xemplo/form-dialog-popover';
import { IconButtonVariant } from '@xemplo/icon-button';
import { DawnCalendar } from '@xemplo/icons';
import {
  InputFieldSize,
  LabelHelperText,
  MaybeErrorOrDescription,
} from '@xemplo/input-utils';

import { DateField } from '../date-field/date-field';
import { DateFormats } from '../date-field/date-field.types';
import { useTextDatePicker } from '../hook';

import * as S from './text-date-picker-v2.style';
import { TextDatePickerV2Props } from './text-date-picker-v2.types';

export const TextDatePickerV2TestId = {
  container: (testId?: string) => `date-picker-container-${testId}`,
  input: (testId?: string) => `date-picker-input-${testId}`,
  segment: (testId?: string) => `date-picker-segment-${testId}`,
  label: (testId?: string) => `date-picker-label-${testId}`,
  calendarIcon: (testId?: string) => `date-picker-calendar-icon-${testId}`,
  labelHelperText: (testId?: string) => `date-picker-label-helper-text-${testId}`,
};

export function TextDatePickerV2(props: Readonly<TextDatePickerV2Props>) {
  const {
    testId,
    format = DateFormats.default,
    description,
    inputProps = {},
    isReadOnly,
    onChange,
    name,
  } = props;

  const {
    label,
    isDisabled,
    id,
    width = '272px',
    inputSize = InputFieldSize.Standard,
  } = inputProps;

  const { register, getFieldState, formState, control } = useFormContext();

  const isRequired = !!control._fields[name]?._f.required;

  const { invalid, error } = getFieldState(name, formState);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const state = useDatePickerState({
    ...inputProps,
    onChange: (value) => {
      if (!inputRef.current) return;
      inputRef.current.value = value ? value.toString() : '';
      const changeEvent = new Event('change');
      inputRef.current.dispatchEvent(changeEvent);
      reactHookFormProps.onChange?.({
        target: inputRef.current,
        type: 'change',
      });
      onChange?.(changeEvent, value);
    },
  });

  const reactHookFormProps = register(name, {
    disabled: isDisabled,
  });

  const {
    errorMessageProps,
    labelProps,
    descriptionProps,
    calendarProps,
    fieldProps,
    groupProps,
    buttonProps,
  } = useDatePicker(
    { 'aria-label': 'Date Picker', ...inputProps, isRequired },
    state,
    containerRef
  );

  const {
    handleCalendarClick,
    handleOnBlur,
    handleContainerFocus,
    handleKeyboardEnter,
    inputFocused,
  } = useTextDatePicker(state);

  const canShowOptionalLabel =
    !isRequired && !isReadOnly && !isDisabled && inputSize === InputFieldSize.Large;

  return (
    <>
      <S.Wrapper $width={width} id={id}>
        <S.Container
          {...groupProps}
          className={classNames({
            'input-disabled': isDisabled,
            'input-has-error': invalid,
            'input-readonly': isReadOnly,
            'input-focused': inputFocused || state.isOpen,
            'has-label': !!label,
            'has-value': !!state.value,
          })}
          $inputSize={inputSize}
          aria-label="Date picker"
          ref={containerRef}
          data-testid={TextDatePickerV2TestId.container(testId)}
        >
          <S.CustomInput
            disabled={isDisabled}
            readOnly={isReadOnly}
            data-testid={TextDatePickerV2TestId.input(testId)}
            {...reactHookFormProps}
            name={name}
            value={state.value ? JSON.stringify(state.value) : ''}
            ref={(e) => {
              reactHookFormProps.ref(e);
              inputRef.current = e;
            }}
          />
          <DateField
            {...fieldProps}
            aria-label="Date Field"
            className={classNames({
              'align-end':
                inputSize === InputFieldSize.Large && (!!state.value || inputFocused),
              hidden: inputSize === InputFieldSize.Large && !state.value && !inputFocused,
              'input-readonly': isReadOnly,
              'calendar-open': state?.isOpen,
            })}
            format={format}
            testId={TextDatePickerV2TestId.segment(testId)}
            data-hasvalue={!!state.value}
            onBlur={handleOnBlur}
            onKeyUp={handleKeyboardEnter}
            onFocus={handleContainerFocus}
          />
          <S.Label
            {...labelProps}
            className={classNames(
              { 'has-label': !!label },
              { 'has-value': !!state?.value || inputFocused }
            )}
            data-testid={TextDatePickerV2TestId.label(testId)}
          >
            {label}

            {canShowOptionalLabel && (
              <LabelHelperText
                data-testid={TextDatePickerV2TestId.labelHelperText(testId)}
                className="label-helper-text"
              >
                (optional)
              </LabelHelperText>
            )}
          </S.Label>
          <S.CalendarIconContainer
            {...buttonProps}
            id="calendar-icon"
            variant={IconButtonVariant.Lighter}
            ariaLabel="Calendar Icon"
            className={classNames(inputSize.toLowerCase(), {})}
            onClick={handleCalendarClick}
            data-testid={TextDatePickerV2TestId.calendarIcon(testId)}
          >
            <DawnCalendar height="24px" width="24px" />
          </S.CalendarIconContainer>
        </S.Container>
        <MaybeErrorOrDescription
          error={invalid}
          errorMessage={error?.message ?? inputRef.current?.validationMessage}
          errorMessageProps={errorMessageProps}
          description={description}
          descriptionProps={descriptionProps}
          testId={testId}
        />
      </S.Wrapper>
      {state.isOpen && (
        <FormDialogPopover
          triggerRef={containerRef}
          state={state}
          testId={testId}
          placement="bottom"
          containerPadding={-10}
        >
          <Calendar {...calendarProps} aria-label="Calendar" />
        </FormDialogPopover>
      )}
    </>
  );
}
