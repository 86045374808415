export const zIndex = {
  TextDropdownPlaceholder: 1,
  TextDropdownLeadingIcon: 1,
  TextDropdownSingleValue: 2,
  CardListHover: 2,
  StickyHeader: 3,
  StickyFooter: 3,
  FloatingButton: 4,
  ToastMessage: 999,
  ModalOverlay: 99,
  Modal: 100,
  ModalCloseButton: 101,
};
