import styled from 'styled-components';

import { AmendmentStatus } from '@xemplo/amendment-status';
import { Avatar as ExpedoAvatar } from '@xemplo/avatar';
import {
  BodyLargeSemiBold,
  BodySmallMedium,
  BodySmallRegular,
  BodySmallSemiBold,
  Colour,
  HeadingStandardMedium,
  HeadingStandardRegular,
  Shadow,
} from '@xemplo/style-constants';

export const LoadingContainer = styled.div`
  display: flex;
  padding: 32px;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  gap: 20px;
  padding: 44px 40px 0;
  position: sticky;
`;
export const LeadIconWrapper = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: ${Colour.Blue[50]};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 30px;
    height: 30px;
    color: ${Colour.Blue[500]};
  }
`;
export const Avatar = styled(ExpedoAvatar)`
  border: 2px solid ${Colour.White[100]};
  ${Shadow.Medium1};
`;

export const WorkerName = styled.h1`
  ${HeadingStandardMedium};
  margin: 0;
`;
export const HeadingLevel2 = styled.h2`
  ${BodyLargeSemiBold};
  margin-bottom: 32px;
`;

export const TaskType = styled.span`
  ${HeadingStandardRegular};
  color: ${Colour.Gray[400]};
`;

export const DLRow = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 8px;
  column-gap: 32px;
  &.single-column {
    grid-template-columns: 1fr;
  }
`;

export const DT = styled.dt`
  ${BodySmallRegular};
  grid-row: 1;
  color: ${Colour.Gray[400]};
`;

export const DD = styled.dd`
  margin: 0;
  ${BodySmallMedium};
  color: ${Colour.Gray[700]};
`;

export const DateText = styled.p`
  ${BodySmallRegular};
  color: ${Colour.Gray[400]};
  margin: 4px 0;
`;

export const Status = styled(AmendmentStatus)`
  margin-bottom: 32px;
`;

export const Pending = styled.span`
  color: ${Colour.Gray[300]};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Divider = styled.hr`
  margin: 32px 0;
  border: none;
  border-bottom: 1px solid ${Colour.Gray[200]};
`;

export const SummaryTable = styled.table`
  margin: 0;
  padding: 0;
  width: 100%;

  tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 8px;
  }

  caption {
    ${BodySmallSemiBold};
    padding-bottom: 8px;
    color: ${Colour.Gray[400]};
    text-align: left;
  }

  th,
  td {
    ${BodySmallRegular};
    text-align: left;
  }

  td {
    ${BodySmallMedium};
  }

  th {
    color: ${Colour.Gray[400]};
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px 32px 40px;
`;
export const AppliesToLabel = styled.div`
  display: flex;
  align-items: center;
  max-height: 21px;
  justify-content: space-between;
  ${BodySmallRegular};
  grid-row: 1;
  grid-column: 1 / 3;
  color: ${Colour.Gray[400]};
`;
