export enum CheckboxSize {
  Standard = 'standard',
  Small = 'small',
}

export type CheckboxProps = {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; //callback function that is called when the toggle is clicked.
  name?: string;
  value: string | number;
  disabled?: boolean;
  id?: string;
  ariaLabel: string;
  size?: CheckboxSize;
  error?: boolean;
  indeterminate?: boolean;
};
