import { SortingState } from '@tanstack/react-table';

import { EIMS_DEV_URL, GP_API_DEV_URL } from '@xemplo/gp-constants';
import { EncodeURIComponent, PagedRequest, SortDirection } from '@xemplo/gp-types';

type WindowExtended = Window &
  typeof globalThis & {
    env: {
      REACT_APP_BASE_URL: string;
      REACT_APP_IDENTITY_URL: string;
    };
  };

export function getUserArea() {
  const userArea = localStorage.getItem('userArea');
  return userArea ?? '';
}

export function BASE_URL() {
  return (
    ((key: keyof WindowExtended['env']) => {
      try {
        return (window as WindowExtended).env[key];
      } catch (error) {
        console.log(`Failed to get ${key} from window.env`);
        return undefined;
      }
    })('REACT_APP_BASE_URL') ?? GP_API_DEV_URL
  );
}

export function EIMS_URL() {
  return (
    ((key: keyof WindowExtended['env']) => {
      try {
        return (window as WindowExtended).env[key];
      } catch (error) {
        console.log(`Failed to get ${key} from window.env`);
        return undefined;
      }
    })('REACT_APP_IDENTITY_URL') ?? EIMS_DEV_URL
  );
}

export function getPayrollApiUrl(endpoint: string) {
  const userArea = getUserArea();
  return `${BASE_URL()}/${endpoint}`.replace('<%userArea%>', userArea);
}

export function getEimsApiUrl(endpoint: string) {
  const userArea = getUserArea();
  return `${EIMS_URL()}/${endpoint}`.replace('<%userArea%>', userArea);
}

export function apiUrlBuilder(apiUrl: string, baseUrl?: string, params?: PagedRequest) {
  const localUrl = baseUrl ?? BASE_URL();
  const formattedApiUrl = apiUrl.includes('<%userArea%>')
    ? apiUrl.replace('<%userArea%>', getUserArea())
    : apiUrl;
  const url = `${localUrl}/${formattedApiUrl}`;
  if (!params) return url;
  const paramSting = buildQueryParams(params);
  return `${url}?${paramSting}`;
}

export function buildQueryParams<T extends object>(params: T) {
  const esc = encodeURIComponent;
  return (Object.keys(params) as Array<keyof T>)
    .map((k) => {
      const queryString =
        typeof params[k] === 'object'
          ? buildFilterParams(params[k] as object)
          : esc(params[k] as EncodeURIComponent);
      return esc(k as EncodeURIComponent) + '=' + queryString;
    })
    .join('&');
}

export function buildFilterParams<T extends object>(params: T) {
  const esc = encodeURIComponent;
  return (Object.keys(params) as Array<keyof T>)
    .map((k) => {
      if (!params[k]) return null;
      const queryString =
        typeof params[k] === 'object'
          ? JSON.stringify(params[k])
          : esc(params[k] as EncodeURIComponent);
      return esc(k as EncodeURIComponent) + ':' + queryString;
    })
    .filter((param) => param !== null)
    .join(',');
}

export function buildSortParams(sortParams: SortingState) {
  if (sortParams.length === 0) return {};
  return {
    sort: {
      [sortParams[0].id]: sortParams[0].desc ? SortDirection.DESC : SortDirection.ASC,
    },
  };
}
