import { Fragment, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { IconButtonSize, IconButtonVariant } from '@xemplo/icon-button';
import { DawnCross16, DawnCross24 } from '@xemplo/icons';
import { MinBreakpoint } from '@xemplo/style-constants';

import { ModalBody, ModalBodyOverflow } from './body';
import { ModalFooter } from './footer';
import { ModalHeader } from './header';
import { useDialogControls, useModal, useScrollAnimation } from './hooks';
import * as S from './modal.style';
import { ModalCloseEvent, ModalSize, ModalTheme } from './modal.types';

export const ModalTestId = {
  wrapper: 'modal-wrapper',
  contentWrapper: 'modal-content-wrapper',
  dialog: 'modal-dialog',
  Body: 'modal-body',
  HeaderTitle: 'modal-header',
  CloseButton: 'modal-close-icon',
};

type ModalProps = {
  className?: string;
};

export const Modal: typeof ModalInternal = (props: ModalProps) => {
  return createPortal(<ModalInternal {...props} />, document.body);
};

const ModalInternal = ({ className }: ModalProps) => {
  const { state, bodyRef, containerRef, contentRef, contentOverflow, headerRef } =
    useModal();
  const { open, header, body, footer, size, theme } = state;
  const { handleDialogClick } = useDialogControls();
  const handleScrollFx = useScrollAnimation();

  useEffect(() => {
    if (open) {
      const bodyEl = bodyRef.current;
      const notMobile = window.matchMedia(MinBreakpoint.medium).matches;

      if (notMobile && bodyEl && headerRef.current) {
        bodyEl.addEventListener('scroll', handleScrollFx);
      }

      return () => bodyEl?.removeEventListener('scroll', handleScrollFx);
    }
  }, [bodyRef, headerRef, open, handleScrollFx]);

  if (!open) return null;
  return (
    <Fragment>
      <S.CustomOverlay data-theme={theme ?? ModalTheme.Light} />
      <S.StyledModal
        ref={containerRef}
        className={className}
        data-size={size ?? ModalSize.Responsive}
        data-overflow={body?.overflow ?? ModalBodyOverflow.Standard}
        data-testid={ModalTestId.dialog}
        data-contentoverflow={contentOverflow}
        onClick={(e) => {
          if (contentRef.current?.contains(e.target as Node) || state.disableOutsideClick)
            return;
          handleDialogClick(e);
        }}
      >
        {open && (
          <S.StyledDialogContent
            data-testid={ModalTestId.contentWrapper}
            data-theme={state.theme ?? ModalTheme.Light}
            $width={state.width}
            ref={contentRef}
          >
            <CloseButton />
            {header && <ModalHeader ref={headerRef} />}
            <ModalBody ref={bodyRef} />
            {footer && <ModalFooter />}
          </S.StyledDialogContent>
        )}
      </S.StyledModal>
    </Fragment>
  );
};

const CloseButton = () => {
  const { state, toggleModal, closeBtnDimension } = useModal();
  const { theme, onCloseCallback, closeButton } = state;
  const handleClose = (e: ModalCloseEvent) => {
    onCloseCallback?.(e as ModalCloseEvent);
    if (!onCloseCallback) {
      toggleModal(false);
    }
  };

  const variant = useMemo(
    () =>
      closeButton?.variant ||
      (theme === ModalTheme.Dark ? IconButtonVariant.Lighter : IconButtonVariant.Default),
    [closeButton, theme]
  );
  const btnSize = useMemo(
    () => closeButton?.dynamicSize?.() ?? closeButton?.size ?? closeBtnDimension.size,
    [closeButton, closeBtnDimension]
  );
  if (closeButton?.hide) return null;
  return (
    <S.StyledCloseButton
      id="modal-close-icon-button"
      ariaLabel="Modal close icon button"
      onClick={handleClose}
      size={btnSize}
      naked
      variant={variant}
      $marginTop={closeBtnDimension.marginTop}
    >
      {btnSize === IconButtonSize.Small ? <DawnCross16 /> : <DawnCross24 />}
    </S.StyledCloseButton>
  );
};
