import { tryJsonParse } from '@xemplo/common-util';
import { AssignTo, PayrunSteps, StatusToStepMap } from '@xemplo/gp-types';

/**
 * This function is needed to filter the payrun list by payrun step. It takes all the statuses in the status to step map and returns a bitwise value of the status ids that match the step.
 * This is needed because the payrun list api only accepts a bitwise value of the status ids to filter by.
 * @param step PayrunStep to filter by
 * @returns a bitwise value of the status ids that match the step
 */
export function getFilterStatusId(step: PayrunSteps): number {
  const statusMap = StatusToStepMap(true);
  const statusIdArray = statusMap[step];
  if (step === PayrunSteps.Complete) {
    statusIdArray.push(18);
  }
  return transformStatus(statusIdArray);
}
/**
 * @returns reduces the input array of numbers to a single numbber that is used by the BE for filtering
 */
export function transformStatus(numbers: number[]): number {
  return numbers.reduce((accumulator: number, currentNumber: number) => {
    return accumulator + Math.pow(2, currentNumber - 1);
  }, 0);
}

/** This function parses the json string for assignTo value on amendments */
export function getAssignedWorker(assignTo: string | null) {
  if (assignTo === 'All') return [{ firstName: '', lastName: '', id: '' }] as AssignTo[]; //Edge case for old data
  return tryJsonParse<AssignTo[]>(assignTo ?? '');
}

export function getAssignedWorkerDisplayName(assignTo: string | null) {
  const parsedAssignTo = getAssignedWorker(assignTo);
  switch (parsedAssignTo?.length) {
    case 0:
    case null:
    case undefined:
      return 'No Workers attached';
    case 1:
      return `${parsedAssignTo[0].firstName} ${parsedAssignTo[0].lastName}`;
    default:
      return `${parsedAssignTo?.length} Workers`;
  }
}

export const getRequestedByUserName = (value?: string | null) => {
  if (!value) return 'N/A';
  try {
    return JSON.parse(value)?.UserName;
  } catch (err) {
    return 'N/A';
  }
};
