import styled from 'styled-components';

import { Colour } from '@xemplo/style-constants';

import { ToggleSize } from './toggle.types';

/** Colour.Blue[500] converted to hex for opacity */
const shadowMapChecked = {
  [ToggleSize.Small]: '0 0 0 8px rgba(11, 120, 255, 0.2)',
  [ToggleSize.Standard]: '0 0 0 12px rgba(11, 120, 255, 0.2)',
};

const shadowMapUnchecked = {
  [ToggleSize.Small]: `0 0 0 8px ${Colour.Black[5]}`,
  [ToggleSize.Standard]: `0 0 0 12px ${Colour.Black[5]}`,
};

export const StyledHiddenInput = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;

  /** If checked, change background color to blue */
  &:checked + span {
    background: ${Colour.Blue[500]};
    /**  If checked, move slider to the right*/
    &::before {
      transform: translateX(16px);
    }
  }
  /** If disabled, remove cursor effect and change opacity */
  &:disabled + span {
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const StyledLabel = styled.label<{ size: ToggleSize }>`
  display: block;
  box-sizing: border-box;
  position: relative;
  width: ${(props) => (props.size === ToggleSize.Standard ? '40px' : '32px')};
  height: ${(props) => (props.size === ToggleSize.Standard ? '24px' : '16px')};
  border-radius: 12px;
`;

export const StyledToggle = styled.span<{ size: ToggleSize }>`
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: ${Colour.Gray[300]};
  box-shadow: ${(props) =>
    props.size === ToggleSize.Standard
      ? '0 0 0 12px transparent'
      : '0 0 0 8px transparent'};
  transition: 0.1s all ease-out;
  border-radius: ${(props) => (props.size === ToggleSize.Standard ? '16px' : '12px')};

  /**  On hover, change background depending on checked state */
  &&:hover {
    ${StyledHiddenInput}:checked + & {
      box-shadow: ${(props) => shadowMapChecked[props.size]};
      background: ${Colour.Blue[600]};
    }

    ${StyledHiddenInput}:not(:checked) + & {
      box-shadow: ${(props) => shadowMapUnchecked[props.size]};
      background: ${Colour.Gray[500]};
    }
  }

  /** White circle for slider - default position is 'off' */
  &::before {
    position: absolute;
    content: '';
    height: ${(props) => (props.size === ToggleSize.Standard ? '16px' : '12px')};
    width: ${(props) => (props.size === ToggleSize.Standard ? '16px' : '12px')};
    left: ${(props) => (props.size === ToggleSize.Standard ? '4px' : '2px')};
    top: ${(props) => (props.size === ToggleSize.Standard ? '4px' : '2px')};
    background: ${Colour.White[100]};
    transition: 0.1s transform ease-out;
    border-radius: 50%;
  }
`;
