import styled, { css } from 'styled-components';

import { BodyXSmallMedium, Colour, Shadow } from '@xemplo/style-constants';

export * from './cell/cell.style';

export const Container = styled.div<{ $minHeight?: number; $minWidth?: number }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  background: ${Colour.White[100]};
  min-height: ${({ $minHeight }) => $minHeight ?? 300}px;
  min-width: ${({ $minWidth }) => $minWidth ?? 922}px;
`;

const Keyline = css`
  border-bottom: 1px solid ${Colour.Gray[200]};
`;

export const Table = styled.table<{ $maxHeight?: number }>`
  border-spacing: 0;
  border-radius: 8px;
  width: 100%;
  table-layout: fixed;

  tbody {
    display: block;
    max-height: ${({ $maxHeight }) => {
      if (!$maxHeight || $maxHeight === 0) return null;
      return `${$maxHeight}px`;
    }};
    overflow: auto;
    transition: all 0.1s ease-out;

    &.keyline {
      ${Keyline};
    }

    tr {
      border-bottom: 1px solid ${Colour.Black[5]};

      &.selected {
        background: linear-gradient(
            0deg,
            rgba(11, 120, 255, 0.04) 0%,
            rgba(11, 120, 255, 0.04) 100%
          ),
          #fff;
      }

      /** This is to allow the hover on last row */
      &:last-child {
        margin-bottom: 16px;
      }

      &:hover {
        ${Shadow.Light1};
      }
    }
  }

  thead,
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    &:first-child {
      > div {
        padding-left: 24px;
      }
    }

    &:last-child {
      > div {
        padding-right: 24px;
      }
    }
  }

  &[data-selectable='true'],
  &[data-expandable='true'] {
    th:not(.extra-col-cell) {
      cursor: pointer;
    }
    th,
    td {
      &:first-child {
        > div {
          display: flex;
        }
      }
    }
    th:nth-child(2),
    td:nth-child(2) {
      > div {
        padding-left: 8px;
      }
    }
  }

  &[data-selectable='false'][data-expandable='true'] {
    th,
    td {
      &:first-child {
        > div {
          padding-left: 16px;
        }
      }
    }
  }
`;

/** Extracted to be used within the body as well */
export const ExtraColumn = css`
  &:first-child {
    &[data-selectable='true'],
    &[data-expandable='true'] {
      width: 48px;
    }

    &[data-selectable='true'][data-expandable='true'] {
      width: 84px;
      > div {
        gap: 8px;
      }
    }
  }
`;

export const TableHeader = styled.thead`
  ${Keyline};
  color: ${Colour.Gray[700]};
  box-sizing: border-box;
  &.scroll-shadow {
    box-shadow: 0px 4px 8px 0px rgba(21, 59, 106, 0.04);
  }

  &.overflow-padding {
    display: block;
    padding-right: 4px;
  }

  th {
    ${BodyXSmallMedium};
    ${ExtraColumn};
  }
`;

export const HeaderCell = styled.th<{ $width?: number }>`
  ${({ $width }) => ($width ? `width: ${$width}px;` : null)};

  &.no-user-select {
    user-select: none;
  }

  &.list-table {
    + th > div {
      margin-left: -34px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  background: ${Colour.Gray[100]};
  transition: all 0.1s ease-out;

  &[data-has-selected='true'] {
    background: ${Colour.Blue[500]};
    color: ${Colour.White[100]};
  }
`;

export const TableDevTools = styled.div`
  position: fixed;
  bottom: 10px;
  left: 0;
  z-index: 1;
`;
