import { DawnTick16, DawnTick24 } from '@xemplo/icons';

import { Rectangle } from './v1/checkbox.styles';
import { CheckboxProps, CheckboxSize } from './v1/checkbox.types';

export const Checkmark = ({ size, checked, indeterminate }: Partial<CheckboxProps>) => {
  if (indeterminate) {
    return <Rectangle data-size={size} />;
  }
  if (!checked) return null;

  return size === CheckboxSize.Standard ? (
    <DawnTick24 fontSize="1.5em" />
  ) : (
    <DawnTick16 />
  );
};

export default Checkmark;
